<template>
    <step-settings-wrapper title="Design Your Photo" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="text-3xl uppercase font-bold tracking-wider">
                Layout
            </div>

            <div class="flex flex-wrap -mx-3 md:-mx-6">

                <div class="w-1/2 px-3 md:w-1/3 md:px-6 mt-6 mb-6">
                    <div class="photoshoot-type cursor-pointer" :class="{'active':preset.photoDesignSettings.rawLayout === 0}" @click="preset.photoDesignSettings.rawLayout = 0">
                        <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white">

                        </div>
                        <div class="uppercase text-xl font-bold tracking-wider text-center leading-relaxed type-title">Square</div>
                    </div>
                </div>

                <div class="w-1/2 px-3 md:w-1/2 md:px-6 mt-6 mb-6 pb-8">
                    <div class="photoshoot-type cursor-pointer h-full" :class="{'active':preset.photoDesignSettings.rawLayout === 1}" @click="preset.photoDesignSettings.rawLayout = 1">
                        <div class="h-full w-1/2 md:w-1/3 mx-auto border-b-3 border-turquoise portrait-box relative overflow-y-hidden bg-white">

                        </div>
                        <div class="uppercase text-xl font-bold tracking-wider text-center leading-normal type-title">Portrait</div>
                    </div>
                </div>

            </div>


            <div class="text-3xl uppercase font-bold tracking-wider">
                Type
            </div>

            <div class="border-b border-gray-custom-3">
                <div class="flex flex-wrap -mx-3 md:-mx-6">

                    <div class="w-1/2 px-3 md:w-1/3 md:px-6 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'active':preset.photoDesignSettings.rawPhotoshootType === 0}" @click="preset.photoDesignSettings.rawPhotoshootType = 0">
                            <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white">

                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-relaxed type-title">Picture</div>
                        </div>
                    </div>

                    <div class="w-1/2 px-3 md:w-1/3 md:px-6 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'active':preset.photoDesignSettings.rawPhotoshootType === 1}" @click="preset.photoDesignSettings.rawPhotoshootType = 1">
                            <div class="flex flex-wrap">
                                <div class="w-1/2">
                                    <div class="square-box-grid-wrapper first">
                                        <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <div class="square-box-grid-wrapper second">
                                        <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <div class="square-box-grid-wrapper third">
                                        <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                    </div>
                                </div>
                                <div class="w-1/2">
                                    <div class="square-box-grid-wrapper fourth">
                                        <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-relaxed type-title">Grid</div>
                        </div>
                    </div>

                    <div class="w-1/2 px-3 md:w-1/3 md:px-6 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'active':preset.photoDesignSettings.rawPhotoshootType === 2}" @click="preset.photoDesignSettings.rawPhotoshootType = 2">
                            <div class="flex flex-wrap mx-auto strip-squares">
                                <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                                <div class="w-full border-b-3 border-turquoise square-box relative overflow-y-hidden bg-white"></div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-normal type-title">Strip</div>
                        </div>
                    </div>

                    <div class="w-1/2 px-3 md:w-1/3 md:px-8 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'gif-active':preset.photoDesignSettings.rawPhotoshootType === 3}" @click="preset.photoDesignSettings.rawPhotoshootType = 3">
                            <div class="relative gif-squares square-box overflow-y-hidden">

                                <div class="w-full absolute z-0" style="transform:translateX(-50%);left:50.5px;">
                                    <div class="w-1/2 square-box absolute left-0 z-10 bg-black"></div>
                                    <div class="w-1/2 border-b-3 border-turquoise square-box absolute right-0 bg-white"></div>
                                    <div class="w-1/2 border-t-3 border-turquoise square-box absolute right-0 bg-black" style="right:13px;top:13px;"></div>
                                </div>
                                <div class="w-full absolute z-10" style="transform:translateX(-50%);left:33.5px;top:20px;">
                                    <div class="w-1/2 square-box absolute left-0 z-10 bg-black"></div>
                                    <div class="w-1/2 border-b-3 border-turquoise square-box absolute right-0 bg-white"></div>
                                    <div class="w-1/2 border-t-3 border-turquoise square-box absolute right-0 bg-black" style="right:13px;top:13px;"></div>
                                </div>
                                <div class="w-full absolute z-20" style="transform:translateX(-50%);left:16.5px;top:40px;">
                                    <div class="w-1/2 square-box absolute left-0 z-10 bg-black"></div>
                                    <div class="w-1/2 border-b-3 border-turquoise square-box absolute right-0 bg-white"></div>
                                    <div class="w-1/2 border-t-3 border-turquoise square-box absolute right-0 bg-black" style="right:13px;top:13px;"></div>
                                </div>
                                <div class="w-full absolute z-30" style="left:0;top:60px;">
                                    <div class="w-1/2 border-b-3 border-turquoise square-box absolute left-0 bg-white"></div>
                                </div>

                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-loose pt-0 md:pt-5 lg:pt-3 type-title">GIF</div>
                        </div>
                    </div>

                    <div class="w-1/2 px-3 md:w-1/3 md:px-6 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'bommerang-active':preset.photoDesignSettings.rawPhotoshootType === 4}" @click="preset.photoDesignSettings.rawPhotoshootType = 4">
                            <div class="relative mx-auto bommerang-squares">
                                <div class="border-b-3 border-turquoise absolute right-0 bg-white top-right-square z-0"><div class="border-t-3 border-turquoise absolute left-0 bg-black bottom-left-square top-right-bottom-left-square z-0"></div></div>
                                <div class="border-b-3 border-turquoise absolute bg-white middle-square left-50 top-50 -translate-50 z-40"></div>
                                <div class="absolute bg-black middle-black-square left-50 top-50 -translate-50 z-20"></div>
                                <div class="border-b-3 border-turquoise absolute left-0 bg-white bottom-left-square z-0"></div>
                                <div class="absolute text-xl icon-down"><font-awesome-icon :icon="downArrowIcon"></font-awesome-icon></div>
                                <div class="absolute text-xl icon-left"><font-awesome-icon :icon="leftArrowIcon"></font-awesome-icon></div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-loose pt-5 type-title">Boomerang</div>
                        </div>
                    </div>

                    <div class="w-1/2 px-6 md:w-1/3 md:px-8 mt-6 mb-6">
                        <div class="photoshoot-type cursor-pointer" :class="{'video-active':preset.photoDesignSettings.rawPhotoshootType === 5}" @click="preset.photoDesignSettings.rawPhotoshootType = 5">
                            <div class="w-full border-b-3 border-turquoise bg-white relative square-box mb-4 md:mb-5 lg:mb-3">
                                <div class="absolute left-50 top-50 -translate-50">
                                    <img alt="play" src="../assets/images/play-icon.png" />
                                </div>
                            </div>
                            <div class="uppercase text-xl font-bold tracking-wider text-center leading-normal type-title md:pt-0 lg:pt-0">Video</div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="pt-5 pb-20">

                <div class="md:flex md:relative md:-mx-8">

                    <div class="md:w-1/2 md:px-8 mb-3 md:mb-0">
                        <div class="text-3xl uppercase font-bold tracking-wider mt-3 mb-2 md:mt-0 md:mb-4">
                            Overlay Image
                        </div>
                        <file-select-upload allowedTypes="image/*" @input="previewOverlayImage" ref="fileUpload" :validate="validateOverlayImage" :isValid="isOverlayImageValid"></file-select-upload>

                        <button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight outline-none w-full mt-5" v-if="(preset.photoDesignSettings && preset.photoDesignSettings.overlayImage && removeOverlay !== true) || newOverlaySet" @click="removeOverlayImage">
                            Remove
                        </button>

                    </div>

                    <div class="md:w-1/2 pt-2 md:px-8">
                        <div class="border-b-3 border-turquoise relative overflow-hidden md:w-11/12" ref="overlayWrapper" :class="{'square-box overlay-image-square-box' : preset.photoDesignSettings.rawLayout === 0, 'overlay-image-portrait-box' : preset.photoDesignSettings.rawLayout === 1}">

                            <!-- <img class="{'absolute' : preset.photoDesignSettings.rawLayout === 0}" :src="api_url+'/media/'+preset.photoDesignSettings.overlayImage" alt="" v-if="preset.photoDesignSettings && preset.photoDesignSettings.overlayImage" /> -->

                            <img :class="{'absolute' : preset.photoDesignSettings.rawLayout === 0}" :src="overlayImage" alt="" />

                            <!--<img class="absolute" src="../assets/images/image_placeholder.jpg" alt="" v-else />-->
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <modal :showing="imageSizeError" transition="scale" @close="imageSizeError = false" class="text-center">
            <div class="text-white text-4xl uppercase font-bold tracking-wider">Invalid Overlay Size</div>
            <div class="text-white text-2xl uppercase font-bold tracking-wider">Overlay image dimension must be of size: <br>1280<span class="lowercase">px</span> <span class="lowercase">x</span> 1280<span class="lowercase">px</span> <br> 1440<span class="lowercase">px</span>  <span class="lowercase">x</span> 1440<span class="lowercase">px</span> <br> 1080<span class="lowercase">px</span>  <span class="lowercase">x</span> 1920<span class="lowercase">px</span> <br> 2316<span class="lowercase">px</span>  <span class="lowercase">x</span> 3088<span class="lowercase">px</span>.</div>
            <button type="button" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/3 mx-auto uppercase mt-2" @click="imageSizeError = false">Ok</button>
        </modal>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue';
    import { faLongArrowAltLeft, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
    import FileSelectUpload from "@/components/FileSelectUpload.vue";
    import Modal from '@/components/Modal.vue';
    import placeHolderImage from '../assets/images/image_overlay_background.png';
    import portraitPlaceHolderImage from '../assets/images/image_overlay_background_portrait.png';

    export default {
        name: 'PhotoDesignSettings',
        props: {
            preset: Object
        },
        data: function() {
            return {
                beforeEditing: {},
                api_url: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                imageSizeError: false,
                isOverlayImageValid: true,
                removeOverlay: false,
                newOverlaySet: false,
                selectedOverlayImage: '',
            }
        },
        components: {
            FileSelectUpload,
            StepSettingsWrapper,
            Modal
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.photoDesignSettings);
        },
        methods: {
            onClose: function(toRoute) {
                //this.preset.photoDesignSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = this.$refs.fileUpload.getTotalFiles();
                this.$emit('close', 'photoDesignSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: async function() {
                const result = await this.$refs.fileUpload.saveFiles();
                if( result !== false ) {
                    this.preset.photoDesignSettings.removeOverlay = this.removeOverlay;
                    this.preset.photoDesignSettings.overlayImageS3Key = result;
                    this.$emit('save', 'photoDesignSettings');
                }
            },
            previewOverlayImage: function(files) {
                const $self = this;
                this.validateOverlayImage(files[0]);
                setTimeout(function() {
                    if( $self.isOverlayImageValid ) {
                        var imageSrc = URL.createObjectURL(files[0]);
                        $self.$refs.overlayWrapper.getElementsByTagName('img')[0].src = imageSrc;
                        $self.selectedOverlayImage = imageSrc;
                    }
                }, 200);
            },
            validateOverlayImage: function(file) {
                let imgEl = new Image();
                const $self = this;
                imgEl.onload = function() {
                    if( ( this.naturalWidth === 1280 && this.naturalHeight === 1280 ) || 
                    ( this.naturalWidth === 1440 && this.naturalHeight === 1440 ) || 
                    ( this.naturalWidth === 1080 && this.naturalHeight === 1920 ) || 
                    ( this.naturalWidth === 2316 && this.naturalHeight === 3088 ) ) {
                        $self.imageSizeError = false;
                        $self.isOverlayImageValid = true;
                        $self.newOverlaySet = true;
                    } else {
                        $self.imageSizeError = true;
                        $self.isOverlayImageValid = false;
                    }
                    imgEl = null;
                };
                imgEl.src = URL.createObjectURL(file);
            },
            removeOverlayImage: function() {
                if( this.preset.photoDesignSettings.rawLayout === 1 ) {
                    this.$refs.overlayWrapper.getElementsByTagName('img')[0].src = portraitPlaceHolderImage;
                } else {
                    this.$refs.overlayWrapper.getElementsByTagName('img')[0].src = placeHolderImage;
                }
                this.removeOverlay = true;
                this.newOverlaySet = false;
                this.$refs.fileUpload.removeFiles(0);
                this.selectedOverlayImage = '';
            }
        },
        computed: {
            leftArrowIcon: function() {
                return faLongArrowAltLeft
            },
            downArrowIcon: function() {
                return faLongArrowAltDown;
            },
            overlayImage: function() {
                if( this.selectedOverlayImage != '' ) {
                    return this.selectedOverlayImage;
                } else if( this.preset.photoDesignSettings && this.preset.photoDesignSettings.overlayImage ) {
                    return this.api_url+'/media/'+this.preset.photoDesignSettings.overlayImage;
                } else {
                    return this.preset.photoDesignSettings.rawLayout === 1 ? portraitPlaceHolderImage : placeHolderImage;
                }
            }
        }
    }
</script>

<style scoped>
    .overlay-image-square-box {
        background-image: url('../assets/images/image_overlay_background.png');
        background-size: contain;
    }
    .overlay-image-portrait-box {
        background-image: url('../assets/images/image_overlay_background_portrait.png');
        background-size: cover;
    }
    .square-box:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .square-box-grid-wrapper {
        padding: 1.5px;
    }
    .strip-squares {
        max-width: 22%;
    }
    .strip-squares > div:not(:last-child) {
        margin-bottom: 3px;
    }
    /*.gif-squares .w-1\/2 {
        width: 46%;
    }*/
    .gif-squares .square-box:after {
        padding-bottom: 90%;
    }
    .bommerang-squares {
        width: 115px;
        height: 111px;
    }
    .bommerang-squares .top-right-square {
        width: 54px;
        height: 50px;
    }
    .bommerang-squares .middle-square {
        width: 51px;
        height: 53px;
    }
    .bommerang-squares .middle-black-square {
        width: 59px;
        height: 61px;
    }
    .bommerang-squares .bottom-left-square {
        width: 55px;
        height: 52px;
        top: 100%;
        transform: translateY(-100%);
    }
    .bommerang-squares .top-right-bottom-left-square {
        width: 26px;
        height: 25px;
    }
    .bommerang-squares .icon-left {
        right: 10px;
        bottom: 28px;
    }
    .bommerang-squares .icon-left:after {
        content: "";
        display: block;
        background: white;
        width: 2px;
        height: 11px;
        position: absolute;
        right: 0;
        top: 3px;
    }
    .bommerang-squares .icon-down {
        left: 10px;
        top: 31px;
    }
    .bommerang-squares .icon-down:after {
        content: "";
        display: block;
        background: white;
        width: 11px;
        height: 2px;
        position: absolute;
        left: 4px;
        top: 4px;
    }
    @media (min-width: 768px) {
        .overlay-image-square-box, .overlay-image-portrait-box {
            right: -1.1rem;
        }
    }
    .photoshoot-type.active .square-box, .photoshoot-type.active .portrait-box, .photoshoot-type.gif-active .gif-squares .bg-white.square-box, .photoshoot-type.bommerang-active .bommerang-squares .bg-white, .photoshoot-type.bommerang-active .bommerang-squares .icon-left:after,  .photoshoot-type.bommerang-active .bommerang-squares .icon-down:after, .photoshoot-type.video-active div:first-child {
        background: #0DE9FF;
    }
    .photoshoot-type.active .type-title, .photoshoot-type.gif-active .type-title, .photoshoot-type.bommerang-active .type-title, .photoshoot-type.bommerang-active .icon-left, .photoshoot-type.bommerang-active .icon-down, .photoshoot-type.video-active .type-title {
        color: #0DE9FF;
    }
    .photoshoot-type.gif-active .gif-squares .w-full:not(:last-child) > div:last-child {
        border-color: #000000;
        top: 16px !important;
    }
</style>